import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { Settings } from '../shared/settings';

@Component({
  selector: 'kht-kht-kreislauf-dyn',
  templateUrl: './kht-kreislauf-dyn.component.html',
  styleUrls: ['./kht-kreislauf-dyn.component.scss']
})
export class KhtKreislaufDynComponent implements OnInit {

  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;
  settings: Settings;
  turnPos: number;
  positionTurn: number;
  slideStatus: boolean;
  kreislaufData: any;
  kreislaufDetail: any;
  nr: any;
  display: boolean;
  videoNr: any;
  statusNavigation: boolean;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pKreislauf = params.get('kreislauf');
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.khts.getKreislaufData(this.pLanguage, this.pKreislauf).subscribe(kreislaufDetail => this.kreislaufDetail = kreislaufDetail);
      this.khts.getSettings(this.pLanguage).subscribe( settings => this.settings = settings);
      this.statusNavigation = false;
    });

    this.document.documentElement.lang = this.pLanguage;

    this.display = true;

    if ( this.pKreislauf !== 'hv' ) {
      this.positionTurn = 13;
      this.nr = 13;
      this.videoNr = 10;
      this.turnPos = 1;
      this.slideStatus = false;
    } else {
      this.turnPos = 0;
    }
  }
  setTurnPos(val: any) {

    if (val <= 9) {
      val = '0' + val;
    }
    this.nr = val;
  }

  setVideoNr(val) {
    val = parseInt(val, 10);
    if ( val <= 2 ) {
      return this.videoNr = 60;
    } else if ( val >= 3 && val <= 5 ) {
      return this.videoNr = 73;
    } else if ( val >= 6 && val <= 8 ) {
      return this.videoNr = 85;
    } else if ( val >= 9 && val <= 11 ) {
      return this.videoNr = 98;
    } else if ( val >= 12 && val <= 13 ) {
      return this.videoNr = 10;
    } else if ( val >= 14 && val <= 15 ) {
      return this.videoNr = 23;
    } else if ( val >= 16 && val <= 18 ) {
      return this.videoNr = 35;
    } else if ( val >= 19 && val <= 20 ) {
      return this.videoNr = 48;
    } else if ( val > 21 ) {
      return this.videoNr = 60;
    }
  }

  setSlideStatus(status: boolean, val: any) {

    this.setVideoNr(val);
    this.slideStatus = status;

    if ( this.slideStatus ) {
      this.display = false;
    } else {
      this.display = true;
    }

  }

  filterBauteile( bauteil ) {
    if (bauteil.bezeichner === 'ec06') {
      return false;
    }
    if (bauteil.bezeichner === 'ec06_visco') {
      return false;
    }
    if (bauteil.bezeichner === 'ec03_thermo') {
      return false;
    }
    if (bauteil.bezeichner === 'ec03_housing') {
      return false;
    }
    if (bauteil.bezeichner === 'ec03_map') {
      return false;
    }
    if (bauteil.bezeichner === 'ec06_lkw_insert') {
      return false;
    }
    if (bauteil.bezeichner === 'ec06_lkw_sleeve') {
      return false;
    }

    return bauteil;

  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
